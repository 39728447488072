@import "../variables";

.form-tabs {
  .d-tab-bar__item {
    background-color: #fff;
    padding: 20px;
    height: 60px;
  } 
  .d-tab-bar__item-active {
    background-color: $primary-color!important;
    color: #fff!important;
    border-bottom: none;
  }
}