// colors
$light-opposite-color:  #3d464d                             !default;
$accent-color:          #125BF5                             !default;
$primary-color:         #125BF5                             !default;
$secondary-color:       #DE0D0C                             !default;
$accent-opposite-color: #fff                                !default;
$link-color:            $accent-color                         !default;
// $link-hover-color:   $accent-color                         !default;
$bg-muted-color:      rgba(245, 245, 245, 1)                !default;
$border-color:        #ECECEC     ;               
$p-medium-size:        14px                        !default;
$p-large-size:        14px                        !default;
$p-small-size:        14px                        !default;
$body-font-size:        14px                        !default;
