$compact-menu-width: 50px;
$expand-menu-width: 300px;

/* ---------------------------------------------------
    CONTENT STYLE
----------------------------------------------------- */
html {
  font-size: 16px;
}
body {
  overflow-y: hidden !important;
  font-size: 0.875rem;
}

@mixin expanding-avatar {
  min-width: 85px !important;
  width: 85px !important;
  height: 85px;
  object-fit: cover;
  margin: 10px;
  border-radius: 15px;
  div.text-white {
    font-size: 40px;
    font-weight: 500;
  }
}

@mixin compact-avatar {
  min-width: 40px !important;
  width: 40px !important;
  height: 40px;
  border-radius: 5px;
  div.text-white {
    font-size: 25px;
    font-weight: 500;
  }
}

@mixin user-expanding-info-view {
  .user-info-avatar {
    @include expanding-avatar();
  }
  text {
    display: inline-block !important;
  }
}

@mixin user-compact-info-view {
  display: flex;
  padding: 10px 0px 0px 0px;
  margin-bottom: 0px;
  .user-info-avatar {
    @include compact-avatar();
  }
  text {
    display: none !important;
  }
}

@mixin menu-compact-view {
  min-width: $compact-menu-width;
  max-width: $compact-menu-width;
  .app-layout__sidebar-header {
    max-width: $compact-menu-width;
    min-width: $compact-menu-width;
    img {
      width: $compact-menu-width - 10px !important;
      height: auto !important;
    }
  }
  .app-layout__menu-list {
    max-width: $compact-menu-width;
    min-width: $compact-menu-width;
    display: block;
    .app-layout__menu-list-item {
      display: flex;
      align-items: center;
      justify-content: center;
      .app-layout__menu-list-title,
      .app-layout__menu-list-icon-arrow {
        display: none;
      }
    }
  }
  .app-layout__sidebar-user-info {
    @include user-compact-info-view();
  }
  .app-layout__sub-menu-list {
    display: none;
  }
  .app-layout__sidebar-button-toggle {
    width: $compact-menu-width;
    .app-layout__sidebar-button-toggle-text {
      display: none;
    }
  }
}

@mixin menu-expanding-view {
  max-width: $expand-menu-width;
  min-width: $expand-menu-width;
  .app-layout__sidebar-header {
    max-width: $expand-menu-width;
    min-width: $expand-menu-width;
  }
  .app-layout__menu-list {
    max-width: $expand-menu-width;
    min-width: $expand-menu-width;
    .app-layout__menu-list-item {
      display: flex;
      flex-direction: column;
      justify-content: start;
      margin-top: 1rem;
      padding-left: 1rem;
      padding-right: 1rem;
      i {
        color: $light-opposite-muted-color;
      }

      :hover {
        cursor: pointer;
      }
    }

    .app-layout__menu-list-item-inner {
      display: flex;
      width: 100%;
      align-items: center;
      cursor: pointer;
    }

    .app-layout__menu-list-item-active {
      background: rgba($color: #ffffff, $alpha: 0.3);

      i {
        color: $light-color;
      }

      .app-layout__menu-list-title {
        color: $light-color;
      }
    }

    .app-layout__sub-menu-item-active {
      .app-layout__menu-list-title {
        color: $light-color !important;
      }
    }

    .app-layout__menu-list-title {
      color: $light-opposite-muted-color;
      margin-left: 20px;
      flex: 1;
    }

    .app-layout__sub-menu-list {
      display: flex;
      flex-direction: column;
      width: 100%;
    }

    .app-layout__sub-menu-item {
      padding: 10px;
      color: $light-opposite-muted-color;
      i {
        color: $dark-color;
      }
    }
    .app-layout__sub-menu-item {
      .app-layout__menu-list-title {
        display: flex !important;
        width: 100% !important;
      }
      text-align: start !important;
    }
  }
  .app-layout__sidebar-user-info {
    @include user-expanding-info-view();
  }
  .app-layout__menu-list-title,
  .app-layout__menu-list-icon-arrow {
    display: inline !important;
    text-align: start !important;
  }
}

#app-layout__content {
  width: 100%;
  min-height: 100vh;
  transition: all 0.3s;
  display: flex;
  flex-direction: column;
  width: calc(100vw - $compact-menu-width);
}

.app-layout__wrapper {
  background-color: whitesmoke !important;
  .app-layout__navbar {
    padding: 8px 16px 8px 4px;
  }
  #app-layout__sidebar {
    max-width: $expand-menu-width;
    min-width: $expand-menu-width;
    .app-layout__sidebar-header {
      max-width: $expand-menu-width;
      min-width: $expand-menu-width;
      padding: 10px;
      align-items: center;
      .app-layout__logo {
        height: 40px;
      }
    }
    .app-layout__sidebar-user-info {
      .user-info-avatar {
        @include expanding-avatar();
      }
    }
    .app-layout__menu-list-item-inner:hover,
    .app-layout__sub-menu-item:hover {
      background-color: rgba($color: #fff, $alpha: 0.2);
    }
  }
  #app-layout__content {
    height: 100% !important;
    overflow-y: scroll !important;
    background-color: transparent !important;
    width: calc(100vw - $expand-menu-width) !important;
  }
  .app-layout__menu-list-item-sub-active {
    background-color: rgba(255, 255, 255, 0.2);
  }
}

.app-layout__wrapper {
  #app-layout__sidebar.active {
    position: absolute;
    left: 0;
    min-width: $compact-menu-width;
    z-index: 9999;
    height: 100%;
    @include menu-compact-view();
  }
  #app-layout__content.active {
    background-color: transparent !important;
    width: calc(100vw - $compact-menu-width) !important;
    margin-left: $compact-menu-width;
  }
}

.app-layout__wrapper {
  #app-layout__sidebar:hover {
    @include menu-expanding-view();
  }
}

.app-layout__wrapper {
  .mouse-enter#app-layout__content.active {
    background-color: transparent !important;
    width: calc(100vw - $compact-menu-width) !important;
  }
  .mouse-enter#app-layout__content {
    background-color: transparent !important;
    width: calc(100vw - $expand-menu-width) !important;
  }
}

.info-table {
  .ant-table-body {
    padding-bottom: 60px;
  }
}

.pswp img {
  object-fit: contain;
}

.d-table-awesome-component__select-column {
  div {
    &:nth-child(2) {
      display: none;
    }
  }
}

.d-input-search__outline,
.d-input-text__input-container-outline,
.d-select__select-outline {
  border: 1px solid #d9d9d9 !important;
}

.show-scroll-bar {
  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.5);
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
}

#app-layout__sidebar {
  background-color: #4f83fe;
}
.app-layout__menu-list-item i,
.app-layout__menu-list-title {
  color: #fff !important;
}

.compare-group {
  .checkbox__container {
    margin-bottom: 0.5rem !important;
    margin-top: 0.5rem !important;
  }
}

.react-daterange-picker {
  width: 100%;
  * {
    border-color: #d9d9d9 !important;
  }
}

.react-daterange-picker__wrapper {
  justify-content: start;
  padding: 6px 10px;
  // .react-daterange-picker__inputGroup,
  // .react-daterange-picker__range-divider,
  // .react-daterange-picker__clear-button {
  //   display: none;
  // }
  // .react-daterange-picker__calendar-button {
  //   svg {
  //     * {
  //       stroke: white;
  //     }
  //   }
  // }
  .react-daterange-picker__inputGroup {
    border: none !important;
    flex-grow: 0;
  }
  .react-daterange-picker__clear-button {
    opacity: 0.6;
  }
  .react-daterange-picker__calendar-button {
    width: 30px;
  }
  .react-daterange-picker__range-divider {
    &+.react-daterange-picker__inputGroup {
      flex: 1;
    }
  }
  .react-daterange-picker__inputGroup__input:invalid {
    background: transparent;
  }
}

.react-calendar {
  background: white;
  padding: 15px;
  text-align: center;
  .react-calendar button {
    margin: 0;
    border: 0;
    outline: none;
    font: inherit;
    font-size: 0.75em;
    &:enabled:hover {
      cursor: pointer;
    }
  }
}

.react-daterange-picker {
  z-index: 999;
}

.react-daterange-picker__calendar-button {
  width: 100%;
  height: 100%;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  &:focus {
    outline: 0 !important;
  }
}

.react-calendar__navigation {
  display: flex;
  height: 32px;
  margin-bottom: 10px;
  justify-content: center;
  align-items: center;
}

.react-calendar__month-view__weekdays {
  margin-bottom: 5px;
  abbr {
    text-decoration: none !important;
  }
}

.react-calendar__navigation button {
  min-width: 44px;
  background: none;
  &:focus {
    outline: 0 !important;
  }
}

.react-calendar__navigation__arrow {
  font-size: 1.5rem;
  color: rgba(0, 0, 0, 0.25);
  transition: all .5s ease;
  &:hover {
    color: rgba(0, 0, 0, 0.75);
  }
  &:focus {
    outline: 0 !important;
    color: rgba(0, 0, 0, 1);
  }
}

.react-calendar__tile {
  max-width: 100%;
  text-align: center;
  padding: 0.4em 0.5em;
  background: none;
  &:focus {
    outline: 0 !important;
  }
}

.react-calendar__tile--active,
.react-calendar__tile--hover {
  background-color: #e6f7ff;
}
.react-calendar__tile--rangeStart,
.react-calendar__tile--rangeEnd {
  background: #125bf5;
  color: #fff;
}

.ant-table-summary {
  display: table-header-group;
  background: #696969;
  color: #fff;
}